
/* Global CSS */

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: "Roboto", sans-serif;
  -moz-osx-font-smoothing: "Roboto", sans-serif;
  font-style: normal;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
:root {
  --primary: #5e3bee;
  --heading-color: #282938;
  --bg-shade: #f5fcff;
  --github: #e62872;
  --darkblue: #1c1e53;
  --black: #000000;
  --white: #ffffff;
}
.btn {
  font-family: "Roboto";
  display: inline-block;
  padding: 14px 32px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  -ms-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
  align-items: center;
}
.btn-outline-primary {
  color: var(--primary);
  background-color: var(--white);
  border: 1px solid var(--primary);
  animation: slideInFromTop 0.5s ease-in-out forwards;
}
.btn-outline-primary:hover {
  color: var(--white);
  background-color: var(--primary);
}
.btn-primary {
  color: var(--white);
  background-color: var(--primary);
  border: 1px solid var(--primary);
}
.btn-primary:hover {
  color: var(--primary);
  background-color: var(--white);
}
.btn-white {
  color: var(--black);
  background-color: var(--white);
}
.btn-white:hover {
  color: var(--white);
  background-color: var(--primary);
}

.btn-white-about-me {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border: none;
  background-color: var(--bg-shade); /* Warna LinkedIn */
  color: #000000;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  text-decoration: none;
  animation: fadeIn 0.5s ease-in-out forwards;
}
.btn-github {
  color: var(--white);
  background-color: var(--github);
  border: var(--github);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.95);
  gap: 16px;
}
.btn-github:hover {
  color: var(--github);
  background-color: var(--white);
}
/* Global CSS Ends */
/* Main Headings */

/* Heading 1 */
h1 {
  font-size: 56px;
  font-weight: 700;
  line-height: 67px;
}
/* Heading 2 */
h2 {
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
}
/* Heading 3 */
h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
}

/* Pragraphs text large medium small */
/* Body 1 */
.text-lg {
  color: var(--darkblue);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}
/* Body 2 */
.text-md {
  color: var(--darkblue);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
/* Body 3 */
.text-sm {
  color: var(--black);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.text-porto-desc {
  color: var(--darkblue);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.text-footer-navbar {
  color: var(--darkblue);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

/* Section Titles */
/* Skills & About Me */
.section--title {
  color: var(--heading-color);
  font-size: 21px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  display: flex;
  align-items: flex-start;
}

.dark .section--title {
  color: #ffffff;
}

.dark .section--title--name {
  background-color: black;
}

/* Portfolio, Testimonial & Contact Me */
.sub--title {
  color: var(--heading-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
}
/* Section Titles Ends */

/* Navbar Style Start */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 85.333px;
  background: var(--white);
  box-shadow: 0px 5.333px 80px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  animation: fadeIn 0.5s ease-in-out;
} 

.navbar--items > ul {
  list-style: none;
  display: flex;
  align-items: flex-start;
  gap: 42.667px;
  text-decoration: none;
  animation: slideInFromTop 0.5s ease-in-out forwards;
}
.navbar--items ul > li > a {
  text-decoration: none;
}
/* Navbar Content */
.navbar--content {
  color: var(--darkblue);
  font-size: 20px;
  font-style: normal;
  font-weight: 200;
  line-height: 24px;
  cursor: pointer;
}

.navbar--active-content {
  color: var(--primary);
  font-weight: 700;
}

.navbar--logo {
  display: flex;
  align-items: flex-start;
  animation: slideInFromTop 0.5s ease-in-out forwards;
}

.navbar--logo img {
  height: 50px;
  cursor: pointer;
}

.dark .navbar--logo {
  border: 0px solid black;
}

/* Switch styles */
.switch {
  position: relative;
  display: inline-block;
  width: 62px;
  height: 26px;
  top: 4px;
  animation: slideInFromTop 0.5s ease-in-out forwards;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  transition: 0.4s;
  border-radius: 34px;
}

.light-mode-round {
  border: 1px solid orange;
}

.dark-mode-round {
  background-color: black;
  border: 1px solid yellow;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 11px;
  bottom: 5px;
  /* background-color: white; */
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #000000;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 50px;
}

.slider.round:before {
  border-radius: 60%;
}

/* Icon styles */
.dark-mode-icon,
.light-mode-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
}

.dark-mode-icon {
  right: 10px;
  color: yellow;
}

.light-mode-icon {
  left: 10px;
  color: orange;
}

/* Navbar Styler Ends */

/* Hero Section Style */
.hero--section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 98.000px 85.333px 133.333px;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  background-color: var(--bg-shade);
  /* background-image: url('../public/img/background-nature.webp'); 
  background-size: cover; 
  background-position: center;  */
  /* animation: fadeIn 0.5s ease-in-out forwards; */
  /* animation-delay: 0.2s; */
}
.hero--section--content-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  /* animation: fadeIn 0.5s ease-in-out forwards; */
  /* animation-delay: 0.4s; */
}
.hero--section--content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 21.333px;
  /* animation: fadeIn 0.5s ease-in-out forwards; */
  /* animation-delay: 0.6s; */
}

.hero--section--content a {
  text-decoration: none;
}

.hero--section--content--box > button {
  margin-top: 21.333px;
}
.hero--section--title {
  color: var(--heading-color);
  font-size: 74.667px;
  font-weight: 700;
  line-height: 90px;
  align-self: stretch;
}
.section--title--name {
  font-size: 2rem;
  background-color: var(--primary);
  color: white;
}
.hero--section--title--color {
  color: var(--primary);
  background-color: #5e3bee;
}

.hero--section-description {
  color: var(--darkblue);
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
}

.hero--section--img {
  display: flex;
}

.hero--section--img > img {
  width: 100%;
  height: 100%;
  /* opacity: 0; */
  /* animation: fadeIn 0.5s ease-in-out forwards; */
  /* animation-delay: 0.8s; */
}
.contact-btn {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border: none;
  background-color: #0073b1; /* Warna LinkedIn */
  color: white;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  text-decoration: none;
  /* animation: fadeIn 0.5s ease-in-out forwards; */
  /* animation-delay: 0.6s; */
}

.linkedin-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  text-decoration: none;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
  justify-content: center;
  align-items: center;
}

.modal.show {
  display: flex;
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 100%;
  height: 14%;
  max-width: 800px;
  border-radius: 5px;  
  text-align: center;
  font-size: 20px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}


/* Hero Section Style Ends */

/* Skills Section Style */
.skills--section {
  display: flex;
  padding: 159.33px 85.33px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 106.667px;
  /* animation: fadeIn 0.5s ease-in-out; */
}
.skills--section--heading {
  color: var(--heading-color);
  font-size: 80px;
  font-weight: 700;
  line-height: 77px;
  text-align: center;
}
.dark .skills--section--heading {
  color: var(--white);
}
.skills--section--container {
  display: grid;
  justify-content: space-around;
  align-items: flex-start;
  gap: 42.6px;
  grid-template-columns: repeat(4, 1fr);
}
.skills--section--card {
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  flex: 1 0 0;
  border-radius: 10.6px;
  background: var(--bg-shade);
  min-height: 250px;
  /* animation: fadeIn 0.5s ease-in-out; */
}
.skills--section--card:hover {
  border-bottom: 4px solid var(--primary);
}
.skills--section--card:hover .skills--section--description {
  color: var(--darkblue);
}
.skills--section--img {
  display: flex;
  padding: 13.3px;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 13.3px;
  border-radius: 10.6px;
  background: #fff;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
}
.skills--section--card--content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  align-self: stretch;
}
.skills--section--title {
  color: var(--heading-color);
  font-size: 32px;
  font-weight: 700;
  line-height: 45px;
  text-align: center;
}
.skills--section--description {
  color: var(--black);
  font-size: 21.3px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}
/* Skills Style Ends */

/* About Us */
.about--section {
  display: grid;
  padding: 133.3px 85.3px;
  align-items: center;
  gap: 114.6px;
  grid-template-columns: repeat(2, 1fr);
}
.about--section--img > img {
  width: 100%;
  height: 100%;
}
/* About Us Ends */

/* My Portfolio Starts */
.portfolio--section {
  display: flex;
  padding: 112px 85px;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  background-color: var(--bg-shade);
  animation: fadeIn 0.5s ease-in-out;
}
.portfolio--container-box {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}
.portfolio--container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 21.3px;
}
.portfolio--grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 42.6px;
  width: 100%;
}
.portfolio--grid-container-content-creator {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 42.6px;
  width: 100%;
}
.portfolio--grid-container-photographer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 42.6px;
  width: 100%;
}
.portfolio--section--container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 42.6px;
  width: 100%;
}
.portfolio--section--img {
  border-radius: 8px;
  width: 100%;
}
.portfolio--section--img > img {
  width: 100%;
}
.portfolio--section--card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex: 1 0 0;
  background: #fff;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.portfolio--section--card--content {
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}
.portfolio--section--title {
  color: var(--heading-color);
}

.link-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 100px;
}

.portfolio--link {
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  gap: 30px;
  /* border-bottom: 1.33px solid var(--primary); */
  padding-bottom: 10.6px;
  font-weight: 600;
}

.portfolio--link1 {
  text-decoration: none;
  display: flex;
  justify-content: end;
  gap: 16px;
  /* border-bottom: 1.33px solid var(--primary); */
  padding-bottom: 10.6px;
  font-weight: 600;
}

.portfolio--link1:hover path {
  stroke: var(--primary);
}

/* .portfolio--section--card:hover path {
  stroke: var(--primary);
  border-bottom: 4px solid var(--primary);
} */

.portfolio--section--card:hover {
  /* stroke: var(--primary); */
  border-bottom: 4px solid var(--primary);
}

.item--link {
  text-decoration: none;
  color: var(--black);
}

.button--container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

.button--see-more {
  background-color: var(--primary);
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.button--see-more:hover {
  background-color: var(--primary-dark);
}

.tabs-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}

.tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  /* width: 100%; */
  /* margin-top: 20px; */
  /* justify-content: space-around; */
}

.tab {
  background-color: white;
  border: none;
  color: black;
  padding: 20px 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  border-radius: 30px;
}

.tab.active {
  background-color: var(--primary);
  color: white;
}

/* Portfolio Ends */

/* Testimonial */
.testimonial--section {
  display: flex;
  padding: 112px 84.3px;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  background-color: var(--bg-shade);
}
.sections--heading {
  color: var(--heading-color);
}
.testimonial--section--card {
  display: flex;
  padding: 42.6px;
  flex-direction: column;
  align-items: flex-start;
  gap: 42.6px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1.33px solid #006b6a;
}
.testimonial--section--card--reviews {
  display: flex;
  align-items: flex-start;
  gap: 5.33px;
}
.testimonial--section--card--author--detail {
  display: flex;
  align-items: center;
  gap: 21.3px;
}
.testimonial--author--name {
  font-weight: 600;
  color: var(--heading-color);
}
.testimonial--author--designation {
  color: var(--darkblue);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}
/* Testimonial Ends */
.contact--section {
  display: flex;
  padding: 150px 85px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 64px;
}
.contact--form--container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(5, auto);
  width: 40%;
  max-width: 600px;
  row-gap: 32px;
  justify-content: center;
}
.container {
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 32px;
  row-gap: 32px;
}
.contact--label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10.6px;
  flex: 1 0 0;
  width: 100%;
}
.contact--input {
  display: flex;
  font-family: "Roboto";
  padding: 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1.333px solid var(--primary);
  background: var(--white);
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Some browsers will not display the caret when using calc, so we put the fallback first */
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.5303 21.2052C16.2374 21.4981 15.7626 21.4981 15.4697 21.2052L7.82318 13.5587C7.53029 13.2658 7.53029 12.791 7.82318 12.4981L8.17674 12.1445C8.46963 11.8516 8.9445 11.8516 9.2374 12.1445L16 18.9071L22.7626 12.1445C23.0555 11.8516 23.5303 11.8516 23.8232 12.1445L24.1768 12.4981C24.4697 12.791 24.4697 13.2658 24.1768 13.5587L16.5303 21.2052Z" fill="%23033333"/></svg>')
    white no-repeat 98.5% !important; /* !important used for overriding all other customisations */
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.5303 21.2052C16.2374 21.4981 15.7626 21.4981 15.4697 21.2052L7.82318 13.5587C7.53029 13.2658 7.53029 12.791 7.82318 12.4981L8.17674 12.1445C8.46963 11.8516 8.9445 11.8516 9.2374 12.1445L16 18.9071L22.7626 12.1445C23.0555 11.8516 23.5303 11.8516 23.8232 12.1445L24.1768 12.4981C24.4697 12.791 24.4697 13.2658 24.1768 13.5587L16.5303 21.2052Z" fill="%23033333"/></svg>')
    white no-repeat calc(100% - 20px); /* Better placement regardless of input width */
}
/*For IE*/
select::-ms-expand {
  display: none;
}
.checkbox--label {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10.6px;
  flex: 1 0 0;
  justify-content: flex-start;
  /* z-index: 1; */
}
input[type="checkbox"] {
  width: 21px;
  height: 21px;
  border: 0.15em solid var(--heading-color);
  border-radius: 0.15em;
  transform: translatey(-0.075em);
}
/* Contact End */

/* Footer Start */
/* Footer Starts */
.footer--container {
  display: flex;
  padding: 106.667px 85.333px;
  flex-direction: column;
  align-items: flex-start;
  background: var(--bg-shade);
  align-items: center;
  align-self: stretch;
}
.footer--link--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.footer--items > ul {
  list-style: none;
  display: flex;
  align-items: flex-start;
  gap: 42.667px;
  text-decoration: none;
}
.footer--items ul > li > a {
  text-decoration: none;
}
.footer--social--icon > ul {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  text-decoration: none;
}
.divider {
  margin: 106px 0 42.67px;
  height: 1.333px;
  width: 100%;
  background: rgba(40, 41, 56, 0.10);
}
.footer--content--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.footer--content {
  color: var(--black);
  font-size: 18.667px;
  font-weight: 400;
  line-height: 28px;
}

/* Responsive Screens */
@media only screen and (max-width: 1800px) {
  .hero--section--title {
    font-size: 68px;
    line-height: 70px;
  }
  .skills--section--title {
    font-size: 28px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 1600px) {
  .skills--section--heading {
    font-size: 54px;
    line-height: 70px;
    text-align: center;
  }
  .skills--section--container {
    gap: 16px;
  }
  .skills--section--card {
    gap: 28px;
  }
  .skills--section--card--content {
    gap: 20px;
  }
  .skills--section--title {
    font-size: 23px;
    line-height: 30px;
  }
  .skills--section--description {
    font-size: 17.333px;
    line-height: 27px;
  }
  .tabs, .tab {
    display: flex;
    flex-direction: row;
  }
}

@media only screen and (max-width: 1400px) {
  .tabs, .tab {
    display: flex;
    flex-direction: row;
  }
}

@media only screen and (max-width: 1200px) {
  .btn-outline-primary {
    display: none;
  }
  .hero--section {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 70px;
  }
  .modal-content {
    width: 320px;
    height: 22vh;
  }

  .navbar .navbar--logo img {
    display: flex;
    justify-content: flex-start;
  }
  
  .navbar--logo {
    display: flex;
    align-items: flex-start;
  }

  .navbar--logo img {
    position: relative;
    top: 20px;
    bottom: 0;
    transform: translate(20px, -20px);
  }

  .navbar--items ul {
    margin-top: 60px;
    margin-bottom: 40px;
  }

  .hero--section--img img {
    width: 35vh;
    height: 35vh;
    margin-top: 20px;
  }

  .hero--section--title,
  .hero--section-description,
  .footer--content {
    text-align: center;
  }

  .hero--section--title span {
    font-size: 22px;
  }

  .container-section-getInTouch {
    /* margin-top: 8px; */
  }

  .skills--section--container,
  .about--section,
  .portfolio--section--container,
  .portfolio--grid-container,
  .portfolio--container-box,
  .tabs, 
  .tab,
  .footer--link--container,
  .footer--items > ul,
  .footer--content--container {
    display: flex;
    flex-direction: column;
  }
  
  .skills--section,
  .portfolio--container,
  .tabs, .tab,
  .hero--section--content,
  .hero--section--content--box {
    align-items: center;
  }
  .portfolio--container-box {
    gap: 30px;
  }
  .skills--section,
  .testimonial--section,
  .contact--section,
  .footer--container {
    gap: 20px;
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .about--section {
    gap: 20px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .testimonial--section--card {
    padding: 25px;
  }
  .hero--section--title,
  .skills--section--heading,
  .sections--heading {
    font-size: 35px;
    line-height: 40px;
    text-align: center;
  }
  .hero--section--content--box {
    gap: 10px;
  }
  .contact--form--container {
    width: 100%;
    /* max-width: 600px; */
  }
  .container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .footer--social--icon > ul,
  .footer--items > ul {
    padding: 0;
    align-items: center;
  }
  .divider {
    margin: 20px;
  }
}

/* Hamburger menu  */

.nav__hamburger {
  display: none;
  width: 1.875rem;
  height: 1.313rem;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  top: 25px;
  right: 25px;
}

.nav__hamburger__line {
  display: block;
  height: 0.188rem;
  width: 100%;
  background-color: #000000;
  border-radius: 0.625rem;
  transition: all ease-in-out 0.2s;
}

@media screen and (max-width: 1200px) {
  .nav__hamburger {
    display: flex;
    z-index: 200;
    align-items: center;
    top: 30px;
  }
  
  .navbar--items {
    display: flex;
  }
  
  .navbar--items {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255);
    top: -20rem;
    left: 0;
    width: 100%;
    transition: all ease-in-out 0.4s;
  }

  .navbar--items ul {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 3rem 0 0.6rem;
    z-index: -100;
  }
  .navbar--items ul li {
    text-align: center;
  }
  .navbar--items ul li a {
    padding: 0.5rem;
  }
  .navbar--items {
    z-index: -1000;
  }
  .navbar--items.active {
    top: 0px;
  }
  .nav__hamburger.active :nth-child(1) {
    transform: rotate(45deg) translate(0.45rem, 0.1875rem);
  }

  .nav__hamburger.active :nth-child(2) {
    opacity: 0;
  }

  .nav__hamburger.active :nth-child(3) {
    transform: rotate(-45deg) translate(0.45rem, -0.1875rem);
  }

  .dark .nav__hamburger .nav__hamburger__line {
    background-color: white; /* Mengubah warna garis menjadi putih */
  }

  .dark .section--title {
    color: #ffffff;
  }

  .dark .section--title--name {
    background-color: #000000;
  }

  .dark .hero--section-title--color {
    color: purple;
  }

  .navbar {
    z-index: 999;
  }

  .dark .navbar {
    z-index: 999;
  }

  .tabs, .tab {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 720px) {
  .navbar {
    justify-content: space-between;
    padding: 15px 20px;
  }
  
  .navbar--logo {
    flex: 1;
  }
  
  .navbar--actions {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  .switch {
    /* margin-left: 1rem;
    /* margin-right: 50px; */
    /* margin-right: 1rem; */
    position: relative;
  }
}

/* Darkmode */
/* Dark Mode Styles */
body.dark-mode {
  background-color: #928d8d;
  color: #ffffff;
}

.navbar.dark {
  background-color: #181818;
}

.navbar .btn-dark-mode {
  background-color: #000000;
  color: #ffffff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 10px;
}

.navbar .btn-dark-mode:hover {
  background-color: #333333;
}

.dark .navbar--content {
  /* color: var(--black); */
  /* color: var(--darkblue); */
  color: #363969;
  font-weight: 300;
}

/* .dark .btn-outline-primary {
  color: var(--primary);
} */

.dark .btn-outline-primary {
  background-color: #181818;
  color: #363969;
  font-weight: 700;
  border: 0px;
}

.dark .btn-outline-primary:hover {
  background-color: var(--primary);
  color: var(--white);
}

.dark .navbar--active-content {
  color: var(--primary);
  font-weight: 700;
}

.dark .nav__hamburger__line {
  background-color: #000000;
  color: #000000;
}

.hero--section.dark {
  background-color: #121212;
  color: #ffffff;
}

.dark .section--title--name {
  background-color: #373737;
}

.hero--section.dark .hero--section-title--color {
  color: #cda4ff;
}

.hero--section.dark .modal-content {
  background-color: #252525;
  color: #ffffff;
}

.skills--section.dark {
  background-color: #181818;
}

.skills--section.btn-dark-mode {
  background-color: #121212;
  color: #ffffff;
}

.skills--section.dark .skills--section--card {
  background-color: #333333;
  border-color: #444444;
}

.skills--section.dark .skills--section--title {
  color: #bb86fc;
}

.skills--section.dark .skills--section--description {
  color: #cccccc;
}

.portfolio--section.dark {
  background-color: #121212;
  color: #ffffff;
}

.dark .sub--title {
  text-align: center;
  color: #ffffff;
}

.dark .tab {
  background-color: #121212;
  color: var(--white);
}

.dark .tab.active {
  background-color: var(--primary);
  color: white;
}

.dark .text-porto-desc {
  color: #121212;
}

.contact--section.dark {
  background-color: #181818;
  color: #ffffff;
}

.dark .text-lg {
  color: #dedede;
}

.dark .text-md {
  color: #f1ebeb;
}

.dark .text-sm {
  color: #a7a0a0;
}

.footer--container.dark {
  background-color: #121212;
  color: #ffffff;
}

.dark .text-footer-navbar {
  color: #363969;
  font-weight: 300;
}

.dark .navbar--active-content {
  color: var(--primary);
  font-weight: 700;
}

.white-icon {
  fill: white !important;
}

/* Responsive Screens Ends*/


/* Animate CSS */
/* Keyframes for animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInFromTop {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}